import React, { useRef, useEffect } from "react"
import Image from "gatsby-image"

import SEO from "../components/seo"
import AnimatedHeadline from "../components/AnimatedHeadline"
import styles from "../styles/_about.module.scss"
import Footer from "../components/footer"
import Button from "../components/Button"
import Kontakt from "../components/kontakt"
import ogImageAgentur from "../images/og-image/og-image-agentur.png"

export default function About({ data }) {
  let cta = useRef(null)

  const headerText =
    "Wir sind Cuuno, ein junges, eifriges Webentwickler und UX Professional Duo mit Sitz in der oberschwäbischen Stadt Aulendorf. Mit umfangreichen Kenntnissen in Planung, Designing, Entwicklung und Evaluation von Webanwendungen, helfen wir ihnen das Maximum aus ihrem Online-Auftritt zu holen."
  return (
    <>
      <SEO
        title="Agentur. Das ist Cuuno."
        description="Wir sind Cuuno, ein junges, eifriges Webentwickler und UX Professional Duo mit Sitz in der oberschwäbischen Stadt Aulendorf. Mit umfangreichen Kenntnissen in Planung, Designing, Entwicklung und Evaluation von Webanwendungen, helfen wir ihnen das Maximum aus ihrem Online-Auftritt zu holen."
        image={ogImageAgentur}
        defaultTitle="Agentur - Cuuno"
        titleTemplate="Agentur"
      />

      <div className="cb-container">
        <div className="cb-herosection">
          <div className="cb-herosection-wrap">
            <AnimatedHeadline
              scrollTo={"#about"}
              offset={0}
              headerText={headerText}
              headerTextClass={"cb-text -mw-50"}
              mobileText="Was steckt hinter Cuuno? Lernen Sie uns kennen."
              desktopText={[
                "Was steckt hinter Cuuno?",
                "Lernen Sie uns kennen.",
              ]}
            />
            {/*      <div ref={el => (headerText = el)} className="cb-text -mw-50">
              <p>
                Wir sind Cuuno, ein junges, eifriges Webentwickler und UX
                Professional Duo mit Sitz in der oberschwäbischen Stadt
                Aulendorf. Mit umfangreichen Kenntnissen in Planung, Designing,
                Entwicklung und Evaluation von Webanwendungen, helfen wir ihnen
                das Maximum aus ihrem Online-Auftritt zu holen.
              </p>
            </div> */}
          </div>
        </div>
      </div>

      <section id="about" className={`about  ${styles.cbMember}`}>
        <div className={`about ${styles.cbMemberWrap}`}>
          <div className={`about ${styles.cbMemberGrid}`}>
            <div className={`about ${styles.cbMemberGridCol}`}>
              <div className={`about ${styles.cbMemberGridColImg}`}>
                <Image
                  loading="eager"
                  className={`about ${styles.gatsbyImageWrapper}`}
                  fluid={data.johannes.childImageSharp.fluid}
                  alt="Foto von Johannes Schwegler"
                />
              </div>
              <div className={`about ${styles.cbMemberGridColName}`}>
                Johannes Schwegler
              </div>
              <div className={`about ${styles.cbMemberGridColTitle}`}>
                Co-founder
              </div>
            </div>

            <div className={`about ${styles.cbMemberGridCol}`}>
              <div className={`about ${styles.cbMemberGridColImg}`}>
                <Image
                  loading="eager"
                  className={`about ${styles.gatsbyImageWrapper}`}
                  fluid={data.oliver.childImageSharp.fluid}
                  alt="Foto von Oliver Hagel"
                />
              </div>
              <div className={`about ${styles.cbMemberGridColName}`}>
                Oliver Hagel
              </div>
              <div className={`about ${styles.cbMemberGridColTitle}`}>
                Co-founder
              </div>
            </div>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className={`about cb-container`}>
        <div data-aos="fade-up" className={`about ${styles.cbMore}`}>
          <div className={`about ${styles.cbMoreWrapper}`}>
            <div className={`cb-header ${styles.cbMoreHeader}`}>
              <h1>Wollen Sie mehr zu unserer Geschichte erfahren?</h1>
            </div>

            <Button text={"Hier mehr lesen"} link={"/blog/wer-wir-sind"} />
          </div>
        </div>
      </section>

      <Kontakt />
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query AgenturQuery {
    johannes: file(relativePath: { eq: "johannesv2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    oliver: file(relativePath: { eq: "lockenoli.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
